/* Colors */
$background: #fefefe;

$text: #4b507a;
$red: #f45e61;
$blue: #0eb7da;

/* Basic Styles */
button {
  background: none;
  border: 0;
  box-sizing: border-box;
  margin: 1em;
  padding: 1em 2em;

  /* Using inset box-shadow instead of border for sizing simplicity */
  box-shadow: inset 0 0 0 2px $red;
  color: $red;
  font-size: 40em;
  font-weight: 700;

  /* Required, since we're setting absolute on pseudo-elements */
  position: relative;
  vertical-align: middle;

  &::before,
  &::after {
    box-sizing: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.spin {
  width: 15em;
  height: 15em;
  padding: 0;

  &:hover {
    color: $blue;
  }

  &::before,
  &::after {
    top: 0;
    left: 0;
  }

  &::before {
    border: 2px solid transparent; /* We're animating border-color again */
  }

  &:hover::before {
    border-top-color: $blue; /* show border */
    border-right-color: $blue;
    border-bottom-color: $blue;

    transition:
      border-top-color 0.15s linear, /* Stagger border appearances */
      border-right-color 0.15s liear 0.10s,
      border-bottom-color 0.15s linear 0.20s;
  }

  &::after {
    border: 0 solid transparent; /* Makes border thinner at the edges? I forgot what I was doing */
  }

  &:hover::after {
    border-top: 2px solid $blue; /*Shows border */
    border-left-width: 2px; /* Solid edges, invisible borders */
    border-right-width: 2px; /* Solid edges, invisible borders */
    transform: rotate(270deg); /* Rotate around circle */
    transition:
      transform 0.4s linear 0s,
      border-left-width 0s linear 0.35s; /* Solid edge post-rotation */
  }
}

.circle {
  border-radius: 100%;
  box-shadow: none;

  &::before,
  &::after {
    border-radius: 100%;
  }
}

body {
  background: $background;
  color: $text;
  font: 300 24px/1.5 Lato, sans-serif;
  margin: 1em auto;
  max-width: 36em;
  padding: 1em 1em 2em;
  text-align: center;
}